/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [

  // {
    
  //   description:
  //     '  DUSSERA AND NUTRITION DAY',
  //   href : '/dussera'
 
  // },
  {
    
    description:
      'DISASTER MANAGEMENT WORKSHSHOP',
    href : '/disaster-managment'
 
  },
//   {
    
//     description:
//       'Prabhat Pheri',
//       href : '/prabhat-pheri'
 
//   },
//   {
    
//     description:
//       'Class 1 & 2 Dance ',
//       href : '/class1-2dance'
//   },
  // {
    
  //   description:
  //     'Fancy Dress activity',
  //   href : '/fancy-dress-activity'
 
  // },
//   {
    
//     description:
//       'Goonj 2022 ',
//     href : '/school-program'
 
//   },  
  // {
    
  //   description:
  //     'Investiture',
  //   href : '/investiture'
 
  // },  
//   {
    
//     description:
//       'Bridging the gap',
//     href : '/bridge-course'
 
//   },
  // {
    
  //   description:
  //     'International Day Of Yoga',
  //   href : '/international-yoga-day'
 
  // },

  // {
    
  //   description:
  //     'Class Assembly 6B',
 
  // },
  // {
    
  //   description:
  //     'Class Assembly 7A',
 
  // },
  // {
    
  //   description:
  //     'Class Assembly 7B',
 
  // },  {
    
  //   description:
  //     'Class Assembly 8A',
 
  // },  {
    
  //   description:
  //     'Class Assembly 8B',
 
  // },  {
    
  //   description:
  //     'Class Assembly 9A',
 
  // },  {
    
  //   description:
  //     'Class Assembly 9B',
 
  // },
 
  // {
  //   image: 'https://assets.maccarianagency.com/backgrounds/img16.jpg',
  //   description:
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  //   title: 'Consectetur adipiscing elit',
  //   author: {
  //     name: 'Jhon Anderson',
  //   },
  //   date: '12 Sep',
  // },
];

const SideBar = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Camps/Excursions/Field Trip
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href={item.href}>
                <Typography variant='h6'>
                  {item.description}
                  
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SideBar;
